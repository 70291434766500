import { faCheck, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar as _Avatar,
  Card as _Card,
  CardContent,
  CardHeader,
  Grid,
  GridItem,
} from '@hailtrace/catalyst-ui';
import * as React from 'react';
import styled from 'styled-components';
import { getCookieDomain } from '../../utils/getCookieDomain';
import { Page, PageTitle } from '../Shared/Page';

const applications = [
  {
    title: 'Admin Panel',
    icon: <FontAwesomeIcon icon={faUsersCog} size="3x" />,
    url: `https://admin${getCookieDomain()}`,
  },
  {
    title: 'QC Tool',
    icon: <FontAwesomeIcon icon={faCheck} size="3x" />,
    url: `https://qc${getCookieDomain()}`,
  },
];

const Dashboard: React.FC = () => {
  return (
    <Wrapper>
      <Page fullWidth>
        <PageTitle title="Canopy Weather Applications" />
        <Grid spacing="3">
          {applications.map((app, i) => {
            return (
              <GridItem lg="2" sm="6" xs="12" md="4" key={`application-${i}`}>
                <a href={app.url} rel="noreferrer" target="_blank">
                  <Card border>
                    <CardHeader title={app.title} />
                    <CardContent>
                      <ApplicationIcon>
                        <Avatar size="xxl">{app.icon}</Avatar>
                      </ApplicationIcon>
                    </CardContent>
                  </Card>
                </a>
              </GridItem>
            );
          })}
        </Grid>
      </Page>
    </Wrapper>
  );
};

export default Dashboard;

const Avatar = styled(_Avatar)`
  background: ${({ theme }) => theme.palette.primary.main};

  & svg {
    color: white;
  }
`;

const Card = styled(_Card)`
  display: flex;
  justify-items: center;
  justify-content: center;
  cursor: pointer;

  ${CardHeader} {
    text-align: center !important;
    justify-items: center;
    justify-content: center;
  }

  &:hover {
    box-shadow: 0px 20px 40px rgba(10, 27, 49, 0.1);
  }
`;

const ApplicationIcon = styled.div`
  display: flex;
  margin-top: 10px;
  justify-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
