import {
  Button,
  CircularProgress,
  Grid,
  GridItem,
  Icons,
  Link,
  TextField,
} from '@hailtrace/catalyst-ui';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import CanopyLogo from '../assets/images/canopy-logo.png';
import dividerImg from '../assets/images/login-divider.png';
import image from '../assets/images/storm-bg.png';
import { useAuthenticate } from '../useAuthenticate';
import { getCookieDomain } from '../utils/getCookieDomain';
import {
  useAuthenticateMutation,
} from '../__generated__/typescript-operations';

const LoginPage: React.FC = () => {
  const { authenticated, setAuthenticated } = useAuthenticate();
  const [username, setUsername] = React.useState<string>(
    'josh111@hailtrace.com'
  );
  const [password, setPassword] = React.useState<string>('Test!123');
  const [error, setError] = React.useState<boolean>(false);
  const cookieDomain = getCookieDomain();

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const [login] = useAuthenticateMutation({
    onCompleted: (data) => {
      if (!data.authenticate || data.authenticate.error) {
        setLoading(false);
        setError(true);
      } else {
        setAuthenticated(true);

        localStorage.setItem('token', data.authenticate.token || '');
        localStorage.removeItem('authId');

        Cookies.set('hailtrace-identity', data.authenticate.token || '', {
          domain: cookieDomain,
        });
      }
    },
    onError: () => {
      setLoading(false);
      setError(true);
    }
  });

  React.useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated]);

  const handleLogin = () => {
    setLoading(true);
    setError(false);

    login({
      variables: {
        input: {
          username,
          password,
        },
      },
    })
  };

  return (
    <Wrapper>
      <Grid spacing="2">
        <GridItem xs="3">
          <LeftHalf>
            <WelcomeBox>
              <p>Welcome to</p>
              <p>CanopyWeather One</p>
              <img
                width="284"
                height="96"
                alt="canopyweather"
                src={CanopyLogo}
              />
            </WelcomeBox>
            <LeftHalfFooter>
              <span>How to Login</span>
            </LeftHalfFooter>
          </LeftHalf>
        </GridItem>
        <GridItem xs="9">
          <RightHalf>
            <LoginFormWrapper>
              <p>Login to CanopyWeather One</p>
              <Button
                color="secondary"
                startIcon={<Icons.Google size="m" />}
                fullWidth
              >
                Login with Google
              </Button>
              <LoginDivider src={dividerImg} alt="divider" />
              <form>
                <FormContainer>
                  <TextField
                    error={error}
                    autoFocus
                    placeholder="John Smith or john.smith@email.com"
                    fullWidth
                    // defaultValue="josh111@hailtrace.com"
                    label="Username or email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUsername(e.target.value)
                    }
                  />
                  <TextField
                    error={error}
                    placeholder="*******"
                    fullWidth
                    label="Password"
                    type="password"
                    // defaultValue="Test!123"
                    actionLabel={
                      <Link variant="label2" href="/forgot-password">
                        Forgot Password?
                      </Link>
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                  />
                </FormContainer>
              </form>
              <LoginButton
                disabled={loading}
                startIcon={
                  loading ? (
                    <CircularProgress color="secondary" size="18px" />
                  ) : undefined
                }
                onClick={handleLogin}
                fullWidth
              >
                {loading ? 'Authenticating...' : 'Login'}
              </LoginButton>
              {error && <ErrorBox>Invalid username or password.</ErrorBox>}
            </LoginFormWrapper>
          </RightHalf>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

const ErrorBox = styled.div`
  margin-top: 15px;
  background: ${({ theme }) => theme.palette.error.light};
  border-radius: 5px;
  padding: 14px 0 14px 30px;
  color: ${({ theme }) => theme.palette.error.contrastText};
`;

const LoginButton = styled(Button)``;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > ${TextField} {
    margin-bottom: 24px;
  }
`;

const LoginDivider = styled.img`
  margin-top: 26px;
  margin-bottom: 26px;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 425px;

  & > :nth-child(1) {
    font-weight: 500;
    margin-bottom: 26px;
  }
`;

const RightHalf = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

const LeftHalfFooter = styled.div`
  position: absolute;
  bottom: 46px;
  left: 38px;
  font-size: 14px;
`;

const WelcomeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  line-height: 190%;
  padding-top: 48px;

  & > :nth-child(2) {
    font-weight: 400;
    font-size: 22px;
  }

  & > :nth-child(1) {
    font-weight: 100;
    font-size: 14px;
  }

  & > img {
    margin-top: 47px;
  }
`;

const LeftHalf = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  justify-content: center;
  border-radius: 10px;
  min-height: 100%;
  background: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;
`;

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  display: flex;
  padding: 10px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.page};
`;

export default LoginPage;
